/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LoadingIcon from "../Icons/LoadingIcon";
import config from "../global/config";

const Login = () => {
	var token = localStorage.getItem("instructorToken");
	const navigate = useNavigate();
	if (token) {
		navigate("/projects");
	}

	const [isLoading, setIsLoading] = useState(false);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [invalidLogin, setInvalidLogin] = useState(false);

	function handleSubmit(e) {
		if (e.currentTarget.checkValidity() === false) {
			e.preventDefault();
			e.stopPropagation();
		}
		const url = config.baseUrl + "/command/auth/login";
		setIsLoading(true);
		axios
			.post(url, { email: email, password: password })
			.then((response) => {
				setInvalidLogin(false);
				localStorage.setItem("instructorToken", response.data.token);
				setIsLoading(false);
				navigate("/projects");
			})
			.catch((error) => {
				setInvalidLogin(true);
				setIsLoading(false);
			});
		e.preventDefault();
		e.stopPropagation();
	}

	return (
		<div style={{ maxWidth: "400px", margin: "0 auto", padding: "1rem" }}>
			<h3
				className="header-text"
				style={{ marginTop: "75px", marginBottom: "1rem" }}>
				Login to your account
			</h3>
			{isLoading && <LoadingIcon />}
			<form onSubmit={handleSubmit}>
				<TextField
					required
					label="Email"
					type="email"
					placeholder="Email"
					style={{ width: "100%" }}
					onChange={(e) => setEmail(e.target.value)}
				/>
				<TextField
					required
					id="outlined-password-input"
					label="Password"
					type="password"
					autoComplete="current-password"
					style={{ width: "100%", marginTop: "30px" }}
					onChange={(e) => setPassword(e.target.value)}
				/>
				{invalidLogin && (
					<p style={{ color: "red" }}>
						Invalid Login. Either email or password is incorrect.
					</p>
				)}
				<Button
					type="submit"
					id="login-button"
					style={{ width: "100%", marginTop: "25px" }}>
					SIGN IN
				</Button>
			</form>
			<p
				className="grey-link-text"
				style={{ textAlign: "center", marginTop: "25px" }}>
				<a
					style={{ cursor: "pointer" }}
					onClick={() => navigate("forgot-password")}>
					Forgot Password?
				</a>
			</p>
			<p className="grey-text contact-footer">
				Need help? Contact us{" "}
				<span className="orange-text">(913) 636-2214</span>
			</p>
		</div>
	);
};

export default Login;
