import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import BackIconText from '../Icons/BackIconText';
import React, { useState } from 'react';
import axios from 'axios';
import config from '../global/config';
import LoadingIcon from '../Icons/LoadingIcon';

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [userEmail, setUserEmail] = useState("");
    const [isSent, setIsSent] = useState(false);
    function handleSubmit(e) {
        setIsLoading(true);
        e.preventDefault();
        e.stopPropagation();

        var token = localStorage.getItem('instructorToken');

        axios.post(config.baseUrl + "/command/auth/sendResetEmail", {
            Email: userEmail
        }, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
        .then((response) => {
            setIsLoading(false);
            setIsSent(true);
        })
        .catch(error => setIsLoading(false))
    }

    return(<div>
            <form onSubmit={handleSubmit}>
                <h3 style={{cursor:"pointer"}} onClick={() => navigate("/")}>
                        <BackIconText></BackIconText>
                    </h3>
                <h2 className="header-text" style={{textAlign:"left"}}>Reset Password</h2>
                {!isSent&&<p className="light-grey-text" style={{textAlign: "left", color: "#2D3C47"}}>
                 Enter the email associated with your account and we’ll send an email with the instructions to reset your password.
                </p>}
                {isSent&&<p className="light-grey-text" style={{textAlign: "left", color: "#2D3C47"}}>
                 If your account exists you will receive an email with a reset link shortly.
                </p>}
                {isLoading &&<LoadingIcon/>}
                {!isSent&&<TextField
                    label="Email"
                    required
                    type="email"
                    placeholder="Email"
                    style={{width: "100%"}}
                    onChange={(e) => setUserEmail(e.target.value)}/>}
                {!isSent&&<Button type="submit" id="forgot-password-button" style={{width: "100%", marginTop: "25px"}}>Send</Button>}
                <hr style={{marginTop:"250px"}}/>
                <p className="grey-text">Need help? Contact us <span className="orange-text">(913) 952-7024</span></p>
            </form>
        </div>)
}

export default ForgotPassword;
