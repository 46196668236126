import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import NavLogo from "../navlogo.png";
import HamburgerMenu from "./HamburgerMenu";
import { useLocation } from "react-router-dom";

const Nav = () => {
	let navBarColor = "#2D3C47";
	const location = useLocation();
	const isAuthPage = location.pathname === "/";

	return (
		<Box sx={{ flexGrow: 1, marginBottom: "100px" }}>
			<AppBar position="fixed" sx={{ background: navBarColor }}>
				<Box
					display={"flex"}
					alignItems={"center"}
					justifyContent={"space-between"}>
					<Toolbar sx={{ padding: 0 }}>
						<img src={NavLogo} alt={"DLTS Logo"} />
					</Toolbar>
					{!isAuthPage && <HamburgerMenu />}
				</Box>
			</AppBar>
		</Box>
	);
};

export default Nav;
