import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Box from '@mui/material/Box';
import EventRepeatTwoToneIcon from '@mui/icons-material/EventRepeatTwoTone';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';

const Calendar = ({
  handleClickDay,
  handleDoubleClick,
  unavailableDates,
  projects,
  setSelectedDate,
  selectedDate,
  dayStatusDropdownOpen,
  setDayStatusDropdownOpen,
  handleSetAvailabilityClick,
  handleSetSingleProjectClick,
}) => {
  const [dropdownPosition, setDropdownPosition] = useState('right bottom');
  const [longPressTimer, setLongPressTimer] = useState(null);
  const [allowContextMenu, setAllowContextMenu] = useState(true);
  const inputRef = useRef(null);
  useEffect(() => {
    const handleContextMenu = (e) => {
      if (!allowContextMenu) {
        e.preventDefault();
      }
    };

    window.addEventListener('contextmenu', handleContextMenu);

    return () => {
      window.removeEventListener('contextmenu', handleContextMenu);
    };
  }, [allowContextMenu]);

  useEffect(() => {
    const handleResize = () => {
      const dropdown = document.querySelector('.drop-list');
      if (dropdown) {
        const dropdown = document.querySelector('.drop-list');
        const dropdownWidth = dropdown.offsetWidth;
        const dropdownHeight = dropdown.offsetHeight;
        const inputRect = inputRef.current.getBoundingClientRect();
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        const spaceLeft = inputRect.left;
        const spaceRight = windowWidth - inputRect.right;
        const spaceBottom = windowHeight - inputRect.bottom;
        const spaceTop = inputRect.top;

        if (spaceRight < dropdownWidth && spaceLeft >= dropdownWidth) {
          setDropdownPosition('left');
        } else {
          setDropdownPosition('right');
        }
        if (spaceTop < dropdownHeight && spaceBottom >= dropdownHeight) {
          setDropdownPosition((prev) => (prev += ' bottom'));
        } else {
          setDropdownPosition((prev) => (prev += ' top'));
        }
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial position calculation
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dayStatusDropdownOpen]);

  const toUTCDate = (date) => {
    if (date) {
      return new Date(date);
    }
    return null;
  };
  const onChange = (dates) => {
    handleClickDay(dates.map((elem) => toUTCDate(elem)));
    setSelectedDate(dates.map((elem) => toUTCDate(elem)));
  };
  const convertArrayToDates = (dataArray) => {
    const datesArray = [];

    dataArray.forEach((item) => {
      const date = new Date(item.year, item.month - 1, item.day);
      datesArray.push(date.getTime());
    });

    return datesArray;
  };

  const datesArray = convertArrayToDates(projects);

  const renderDayContents = (day, date) => {
    const isProject = datesArray.some(
      (elem) => new Date(date).getTime() === elem
    );
    const isUnavailable = unavailableDates.some(
      (elem) => new Date(date).getTime() === elem
    );

    let classNames = '';
    const status = {};

    if (isUnavailable) {
      classNames += ' unavailable';
      status.isUnavailable = true;
    }

    const uniqueProjects = projects.filter(
      (project) =>
        project.day === date.getDate() &&
        project.month === date.getMonth() + 1 &&
        project.year === date.getFullYear()
    );

    if (isProject) {
      if (uniqueProjects.length > 1) {
        classNames += ' projects';
      } else {
        classNames += ' project';
      }
      status.isProject = true;
    }

    const handleLongPressStart = (date, status, uniqueProjects) => {
      setAllowContextMenu(false);
      const timer = setTimeout(() => {
        setSelectedDate([date, date]);
        handleDoubleClick(date, status, uniqueProjects);
      }, 500);

      setLongPressTimer(timer);
    };

    const handleLongPressEnd = () => {
      setAllowContextMenu(true);
      clearTimeout(longPressTimer);
      setLongPressTimer(null);
    };

    return (
      <Box sx={{ position: 'relative' }}>
        <Box
          sx={{ position: 'relative', userSelect: 'none' }}
          className={classNames.trim()}
          onDoubleClick={() =>
            handleDoubleClick(toUTCDate(date), status, uniqueProjects)
          }
          onTouchStart={() =>
            handleLongPressStart(toUTCDate(date), status, uniqueProjects)
          }
          onTouchEnd={handleLongPressEnd}
          onTouchMove={handleLongPressEnd}
        >
          {day}
        </Box>
        {dayStatusDropdownOpen > 0 &&
          dayStatusDropdownOpen.toString() === toUTCDate(date).toString() && (
            <>
              <div
                className="drop-backdrop"
                onClick={() => setDayStatusDropdownOpen(-1)}
              ></div>
              <ul className={`drop-list ${dropdownPosition}`} ref={inputRef}>
                {isUnavailable && (
                  <li
                    className="drom-item"
                    onClick={(e) => handleSetAvailabilityClick(e)}
                  >
                    <EventRepeatTwoToneIcon />
                    <span>Manage unavailability</span>
                  </li>
                )}
                {uniqueProjects.map((el) => (
                  <li
                    key={el.name}
                    onClick={() =>
                      handleSetSingleProjectClick(toUTCDate(date), el)
                    }
                    className="drom-item"
                  >
                    <InfoTwoToneIcon />
                    <span style={{ fontWeight: 700 }}>{el.name}</span>
                  </li>
                ))}
              </ul>
            </>
          )}
      </Box>
    );
  };

  return (
    <DatePicker
      onChange={onChange}
      startDate={selectedDate[0]}
      endDate={selectedDate[1]}
      renderDayContents={renderDayContents}
      selectsRange
      inline
    />
  );
};

export default Calendar;
