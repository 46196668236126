import React from 'react'
import { useNavigate } from 'react-router-dom';

const YellowAddButton = () => {
    const navigate = useNavigate();
    return(<>
            <svg onClick={() => navigate("/record-time")} style={{position: "fixed", bottom: 90, right: 30}} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_181_1324)">
                <g filter="url(#filter0_d_181_1324)">
                <path d="M25 45.8333C36.5059 45.8333 45.8333 36.5059 45.8333 25C45.8333 13.494 36.5059 4.16663 25 4.16663C13.494 4.16663 4.16663 13.494 4.16663 25C4.16663 36.5059 13.494 45.8333 25 45.8333Z" fill="#EDCD1F"/>
                </g>
                <path d="M25 16.6666V33.3333" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M16.6666 25H33.3333" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                <filter id="filter0_d_181_1324" x="1.16663" y="3.16663" width="47.6666" height="47.6666" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="2"/>
                <feGaussianBlur stdDeviation="1.5"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.298958 0 0 0 0 0.378886 0 0 0 0 0.4375 0 0 0 0.2 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_181_1324"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_181_1324" result="shape"/>
                </filter>
                <clipPath id="clip0_181_1324">
                <rect width="50" height="50" fill="white"/>
                </clipPath>
                </defs>
            </svg>
    </>)
}

export default YellowAddButton;