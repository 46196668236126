import Button from '@mui/material/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import React, { useState } from 'react';
import axios from 'axios';
import config from '../global/config';
import LoadingIcon from '../Icons/LoadingIcon';


const ValidateEmailResetCode = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [isLoading, setIsLoading] = useState(false);
    const [isInvalid, setIsInvalid] = useState(false);

    function handleSubmit(e) {        
        e.preventDefault();
        e.stopPropagation();
        const searchParams = new URLSearchParams(location.search);
        const resetCode = decodeURIComponent(searchParams.get('code'));
        const emailAddress = searchParams.get('emailAddress');
        setIsLoading(true);
        axios.post(config.baseUrl + "/command/auth/validateresetcode", {
            EmailAddress: emailAddress,
            Code: resetCode
        })
        .then((response) => {
            setIsLoading(false);
            navigate("/new-password", {state: {resetCode: resetCode, email: emailAddress, token: response.data.token }});
        })
        .catch((error) => {
            setIsLoading(false);
            setIsInvalid(true);
        })
    }

    return (<>
        <form onSubmit={handleSubmit}>
            <p style={{textAlign: "center", marginTop: "150px"}}>
                <svg width="151" height="104" viewBox="0 0 151 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M142.065 0H0V89.1465H142.065V0Z" fill="#2D3C47"/>
                <path d="M0 0L71.0325 41.9068L142.065 0H0Z" fill="#EDCD1F"/>
                <path d="M124.121 103.382C138.798 103.382 150.695 91.4844 150.695 76.8077C150.695 62.1311 138.798 50.2333 124.121 50.2333C109.444 50.2333 97.5466 62.1311 97.5466 76.8077C97.5466 91.4844 109.444 103.382 124.121 103.382Z" fill="#EDCD1F"/>
                <path d="M119.564 91.1201C119.061 91.1201 118.576 90.9275 118.21 90.5819L108.115 81.0572C107.735 80.698 107.512 80.2023 107.497 79.6792C107.482 79.156 107.675 78.6483 108.034 78.2676C108.394 77.8869 108.889 77.6646 109.413 77.6494C109.936 77.6342 110.443 77.8275 110.824 78.1867L119.455 86.331L137.31 66.6546C137.662 66.267 138.153 66.035 138.676 66.0096C139.199 65.9843 139.71 66.1676 140.098 66.5192C140.485 66.8709 140.717 67.3621 140.743 67.8848C140.768 68.4075 140.585 68.9189 140.233 69.3065L121.025 90.4729C120.849 90.6672 120.636 90.8244 120.398 90.9352C120.16 91.0461 119.903 91.1084 119.64 91.1186C119.615 91.1193 119.589 91.1201 119.564 91.1201Z" fill="#2D3C47"/>
                </svg>
            </p>
            <h2 className="header-text">Check your email</h2>
            <p className="grey-text">Confirm that you would like to reset your password.</p>
            {isLoading &&<LoadingIcon/>}
            <Button type="submit" id="verify-code-button" style={{width: "100%", marginTop: "25px"}}>Confirm</Button>
            <hr style={{marginTop:"200px"}}/>
            <p className="grey-text">Didn't receive the email? Check your spam or <span className="orange-text"><a style={{cursor: "pointer"}} onClick={() => navigate("/forgot-password")}>try another email address</a></span></p>
        </form>
    </>)
}

export default ValidateEmailResetCode;
