import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";

import {
  Box,
  DialogActions,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import axios from "axios";
import config from "../global/config";

const AvailabilityRangeModalItem = (props) => {
  const {
    dateData,
    setChangeMade,
    setErrorMessage: setErrorGlobal,
    setDates,
    idx,
    setSubmitAllowedList
  } = props;
  const [error, setErrorMessage] = useState("");

  const [isAvailable, setIsAvailable] = useState(dateData[idx].available);
  const [isFullDay, setIsFullDay] = useState(dateData[idx].fullDay);
  const [startValue, setStartValue] = useState(dateData[idx].startTime);
  const [endValue, setEndValue] = useState(dateData[idx].startTime);
  const [notes, setNotes] = useState(dateData[idx].notes);
  useEffect(() => {
    setIsAvailable(dateData[idx].available);
  }, [dateData[idx].available]);
  useEffect(() => {
    setIsFullDay(dateData[idx].fullDay);
  }, [dateData[idx].fullDay]);
  useEffect(() => {
    setStartValue(dateData[idx].startTime);
    setEndValue(dateData[idx].endTime);
  }, [dateData[idx].startTime, dateData[idx].endTime]);
  useEffect(() => {
    setNotes(dateData[idx].notes);
  }, [dateData[idx].notes]);

  useEffect(() => {
    if(isFullDay) {
      setSubmitAllowedList(prev => prev.filter(item => item !== idx));
    }
  }, [isFullDay]);

  const handleSwitchFlip = (e) => {
    setIsAvailable(!isAvailable);
    if (!isAvailable) {
      setIsFullDay(true);
    }
    if (isAvailable && !isFullDay) {
      setIsFullDay(true);
    }
    const dateDataCopy = [...dateData];
    dateDataCopy[idx] = {
      ...dateData[idx],
      available: !isAvailable,
      fullDay: isFullDay,
    };
    setDates(dateDataCopy);

    setErrorMessage("");
  };

  const handleNotesChange = (e) => {
    const newNotes = e.target.value
    const dateDataCopy = [...dateData];
    dateDataCopy[idx] = { ...dateData[idx], notes: newNotes };

    setNotes(newNotes);
    setDates(dateDataCopy);
    console.log('dateDataCopy, ', dateDataCopy)
  };

  const handleEntireDaySwitchFlip = (e) => {
    setIsFullDay(!isFullDay);

    const dateDataCopy = [...dateData];
    dateDataCopy[idx] = { ...dateData[idx], fullDay: !isFullDay };

    setDates(dateDataCopy);
    if (isFullDay && !startValue) {
      setSubmitAllowedList(prev => {
        const isDuplicate = prev.some(el => el === idx);
        if (isDuplicate) return [...prev]
        return [...prev, idx]
      })
    }
  };

  function handleStartTimeChange(e) {
    setStartValue(e.target.value);
    setChangeMade(true);

    const dateDataCopy = [...dateData];
    dateDataCopy[idx] = { ...dateData[idx], startTime: e.target.value };
    setDates(dateDataCopy);
    if (e.target.value + 1 > endValue && !isFullDay) {
      setErrorMessage("Start time must be less than end time");
      setErrorGlobal("Start time must be less than end time");
    } else {
      setErrorMessage(null);
      setErrorGlobal(null);
    }
    if(!e.target.value) {
      setSubmitAllowedList(prev => {
        const isDuplicate = prev.some(el => el === idx);
        if(isDuplicate) return [...prev]
        setErrorMessage("Start time should not be empty");
        setErrorGlobal("Start time should not be empty");
        return  [...prev, idx]
      })
    } else {
      setSubmitAllowedList(prev => prev.filter(item => item !== idx));
    }
  }

  function handleEndTimeChange(e) {
    setEndValue(e.target.value);
    setChangeMade(true);

    const dateDataCopy = [...dateData];
    dateDataCopy[idx] = { ...dateData[idx], endTime: e.target.value };

    setDates(dateDataCopy);

    if (e.target.value < startValue + 1 && !isFullDay) {
      setErrorMessage("End time must be greater than start time.");
      setErrorGlobal("End time must be greater than start time.");
    } else {
      setErrorMessage(null);
      setErrorGlobal(null);
    }
    if(!e.target.value) {
      setSubmitAllowedList(prev => {
        const isDuplicate = prev.some(el => el === idx);
        if(isDuplicate) return [...prev]
        setErrorMessage("End time should not be empty");
        setErrorGlobal("End time should not be empty");
        return  [...prev, idx]
      })
    } else {
      setSubmitAllowedList(prev => prev.filter(item => item !== idx));
    }
  }

  function formatDate(date) {
    let day = date.getDate();
    let month = date.getMonth() + 1; // Add 1 because months are 0-based
    let year = date.getFullYear();

    // Add leading zeros if day or month is less than 10
    day = day < 10 ? "0" + day : day;
    month = month < 10 ? "0" + month : month;

    return month + "/" + day + "/" + year;
  }

  useEffect(() => {
    if (isFullDay) {
      setStartValue("");
      setEndValue("");
    }
    if (!isFullDay) {
      setStartValue(dateData[idx].startTime);
      setEndValue(dateData[idx].endTime);
    }
  }, [isFullDay]);

  return (
    <>
      <TableRow>
        <TableCell
          sx={{ paddingTop: { xs: "0px" }, paddingBottom: { xs: "0px" } }}
        >
          {formatDate(dateData[idx].date)}
        </TableCell>
        <TableCell
          sx={{ paddingTop: { xs: "0px" }, paddingBottom: { xs: "0px" } }}
        >
          <FormControlLabel
            sx={{ minWidth: "144px" }}
            control={
              <Switch onChange={handleSwitchFlip} checked={isAvailable} />
            }
            label={isAvailable ? "Available" : "Unavailable"}
          />
        </TableCell>
        <TableCell
          sx={{ paddingTop: { xs: "0px" }, paddingBottom: { xs: "0px" } }}
          style={{
            opacity: !isAvailable ? "1" : "0.5",
            pointerEvents: !isAvailable ? "all" : "none",
          }}
        >
          <FormControlLabel
            sx={{
              minWidth: "208px",
              "& .Mui-checked": {
                "& .MuiSwitch-thumb": {
                  backgroundColor: !isAvailable ? "#EA710B" : "#fff",
                },
              },
              "& .Mui-checked + .MuiSwitch-track": {
                backgroundColor: !isAvailable ? "#EA710B" : "#000",
                opacity: !isAvailable ? "0.5" : "0.38",
                transition: "none",
              },
            }}
            control={
              <Switch
                onChange={handleEntireDaySwitchFlip}
                checked={isFullDay}
              />
            }
            label={isFullDay ? "Entire Day" : "Specific Time Range"}
          />
        </TableCell>
        <TableCell
          sx={{ paddingTop: { xs: "0px" }, paddingBottom: { xs: "0px" } }}
          style={{
            opacity: !isFullDay ? "1" : "0.5",
            pointerEvents: !isFullDay ? "all" : "none",
          }}
        >
          <TextField
            id={"unavailableStartTime"}
            onChange={handleStartTimeChange}
            sx={{ marginBottom: "10px", marginTop: "10px" }}
            type="time"
            label="Start Time"
            value={startValue}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </TableCell>
        <TableCell
          sx={{ paddingTop: { xs: "0px" }, paddingBottom: { xs: "0px" } }}
          style={{
            opacity: !isFullDay ? "1" : "0.5",
            pointerEvents: !isFullDay ? "all" : "none",
          }}
        >
          <TextField
            id={"unavailableEndTime"}
            onChange={handleEndTimeChange}
            sx={{ marginBottom: "10px", marginTop: "10px" }}
            type="time"
            label="End Time"
            value={endValue}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </TableCell>
      </TableRow>
      {!isAvailable && (
        <TableRow>
          <TableCell colSpan={5}>
            <TextField
              fullWidth
              label="Notes"
              multiline
              variant="outlined"
              value={notes}
              onChange={handleNotesChange}
            />
          </TableCell>
        </TableRow>
      )}
      {error?.length > 0 && (
        <TableRow>
          <TableCell
            sx={{ paddingTop: { xs: "0px" }, paddingBottom: { xs: "0px" } }}
            colSpan={5}
          >
            <DialogContentText
              style={{ color: "red", marginLeft: "5px", textAlign: "right" }}
              id="invalid-values"
            >
              {error}
            </DialogContentText>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};






const AvailabilityRangeModal = (props) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [switchChecked, setSwitchChecked] = useState(props.dateData.available);
  const [entireDayChecked, setEntireDayChecked] = useState(true);
  const [changeMade, setChangeMade] = useState(false);
  const [startTime, setStartTime] = useState(props.dateData.startTime);
  const [endTime, setEndTime] = useState(props.dateData.endTime);
  const [showTimeForm, setShowTimeForm] = useState(false);
  const [timeFormChangeMade, setTimeFormChangeMade] = useState(false);
  const [dates, setDates] = useState(
    getDatesBetween(props.dateData[0], props.dateData[1])
  );
  const [initialDates, setInitialDates] = useState(null);
  const [fetchedDate, setFetchedDate] = useState([]);
  const [allDatesAvailability, setAllDatesAvailability] = useState(false);
  const [allEntireDay, setAllEntireDay] = useState(false);
  const [datesUpdatedForAvailability, setDatesUpdatedForAvailability] = useState(false);
  const [submitAllowedList, setSubmitAllowedList] = useState([]);

  useEffect(() => {
  }, [submitAllowedList]);
  const hasChanged = JSON.stringify(dates) !== JSON.stringify(initialDates);

  function handleSwitchFlip(e) {
    setSwitchChecked(e.target.checked);
    if (e.target.checked != props.dateData.available) {
      setChangeMade(true);
    } else {
      setChangeMade(false);
    }
  }

  function handleSwitchAllAvailability() {
    const updatedDates = dates.map((date) => ({
      ...date,
      available: !allDatesAvailability,
    }));
    setDates(updatedDates);
    setAllDatesAvailability(!allDatesAvailability);
  }

  function handleSwitchAllEntireDay() {
    const updatedDates = dates.map((date, i) => {
      setSubmitAllowedList(prev => {
        const isDuplicate = prev.some(el => el === i);
        if (isDuplicate) return [...prev]
        return [...prev, i]
      })
      if (!date.available) {
        return {
          ...date,
          fullDay: !allEntireDay,
        };
      } else {
        return {
          ...date,
        };
      }
    });
    setDates(updatedDates);
    setAllEntireDay(!allEntireDay);
  }

  useEffect(() => {
    if (allEntireDay) {
      setSubmitAllowedList([])
    }
  }, [allEntireDay]);

  function resetAndClose() {
    setSwitchChecked(props.dateData.available);
    setChangeMade(false);
    setStartTime(null);
    setEndTime(null);
    setErrorMessage(null);
    setTimeFormChangeMade(false);
    setShowTimeForm(false);
    props.handleClose();
    props.setSelectedDate([]);
  }

  function getLabel() {
    if (!changeMade) {
      return props.dateData.available ? <>Available</> : <>Unavailable</>;
    }

    return switchChecked ? <>Available</> : <>Unavailable</>;
  }

  function getDatesBetween(startDate, endDate) {
    let dates = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      dates.push({
        date: new Date(currentDate),
        available: true,
        fullDay: true,
        startTime: "",
        endTime: "",
        notes: "",
      });
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  }

  var token = localStorage.getItem("instructorToken");

  useEffect(() => {
    const allUnavailable = dates.every((date) => date.available);
    setAllDatesAvailability(allUnavailable);
  }, [dates]);

  useEffect(() => {
    const allUnavailable = dates.every((date) => date.fullDay);
    setAllEntireDay(allUnavailable);
  }, [dates]);

  useEffect(() => {
    if (allDatesAvailability) {
      const updatedDates = dates.map((date) => ({
        ...date,
        fullDay: true,
      }));
      setDates(updatedDates);
    }
  }, [allDatesAvailability]);

  useEffect(() => {
    if (allDatesAvailability && !datesUpdatedForAvailability) {
      setDatesUpdatedForAvailability(true);
      setAllEntireDay(true);
      const updatedDates = dates.map((date) => ({
        ...date,
        fullDay: true,
      }));

      setDates(updatedDates);
    }
  }, [allDatesAvailability, dates, datesUpdatedForAvailability]);

  useEffect(() => {
    const res = axios
      .get(config.baseUrl + "/query/instructors/unavailability", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setFetchedDate(response.data);
      });
  }, []);

  useEffect(() => {
    const dataToPass = dates.map((sd) => {
      const fetched = fetchedDate.find(
        (fd) =>
          new Date(fd.date).toDateString() === new Date(sd.date).toDateString()
      );
      if (fetched) {
        console.log('fetached, ', fetched);
        return {
          ...sd,
          available: false,
          fullDay: !(fetched.startTime && fetched.endTime),
          startTime: fetched.startTime || sd.startTime,
          endTime: fetched.endTime || sd.endTime,
          notes: fetched.notes || '',
        };
      } else {
        return sd;
      }
    });
    setDates(dataToPass);

    setInitialDates(dataToPass);
  }, [props.dateData, fetchedDate]);

  let changedData = dates?.filter((elem, idx) => {
    return JSON.stringify(elem) !== JSON.stringify(initialDates?.[idx]);
  });

  changedData = changedData.map((elem) => {
    if (elem.fullDay) {
      elem = { ...elem, startTime: null, endTime: null };
    }
    return { ...elem };
  });

  return (
    <Dialog
      open={props.open}
      onClose={resetAndClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ "& .MuiPaper-root": { maxWidth: "900px", width: "100%" } }}
      PaperProps={{
        sx: {
          margin: '1rem',
        },
      }}
    >
      <br></br>
      <IconButton
        aria-label="close"
        onClick={resetAndClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle id="alert-dialog-title">
        Manage Availability
      </DialogTitle>
      <TableContainer
        sx={{
          maxHeight: "400px",
          overflowX: { xs: "scroll" },
          paddingBottom: "2rem",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Dates</TableCell>
              <TableCell>
                <Box display="flex" alignItems="center">
                  Availability
                  <span></span>
                  <Switch
                    onChange={handleSwitchAllAvailability}
                    checked={allDatesAvailability}
                  />
                </Box>
              </TableCell>
              <TableCell
                style={{
                  opacity: !allDatesAvailability ? "1" : "0.5",
                  pointerEvents: !allDatesAvailability ? "all" : "none",
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{
                    minWidth: "208px",
                    "& .Mui-checked": {
                      "& .MuiSwitch-thumb": {
                        backgroundColor: !allDatesAvailability
                          ? "#EA710B"
                          : "#fff",
                      },
                    },
                    "& .Mui-checked + .MuiSwitch-track": {
                      backgroundColor: !allDatesAvailability
                        ? "#EA710B"
                        : "#000",
                      opacity: !allDatesAvailability ? "0.5" : "0.38",
                      transition: "none",
                    },
                  }}
                >
                  EntireDay
                  <span></span>
                  <Switch
                    onChange={handleSwitchAllEntireDay}
                    checked={allEntireDay}
                  />
                </Box>
              </TableCell>
              <TableCell>StartTime</TableCell>
              <TableCell>EndTime</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dates.map((item, idx) => (
              <AvailabilityRangeModalItem
                error={errorMessage}
                setDates={setDates}
                idx={idx}
                setChangeMade={setChangeMade}
                setErrorMessage={setErrorMessage}
                dateData={dates}
                handleSwitchFlip={handleSwitchFlip}
                getLabel={getLabel}
                key={idx}
                setSubmitAllowedList={setSubmitAllowedList}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <DialogActions sx={{ paddingBottom: "1rem" }}>
        <Button
          disabled={!!errorMessage || !hasChanged || submitAllowedList.length > 0}
          onClick={() => props.handleSubmit(changedData)}
          style={{
            margin: "auto",
            backgroundColor: (!!errorMessage || !hasChanged || submitAllowedList.length > 0) && "gray",
          }}
          id="availability-submit-button"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AvailabilityRangeModal;
