import { useNavigate } from "react-router-dom";
import {Grid, Button } from '@mui/material';

const SuccessRespondedToInvitiation = () => {
    const navigate = useNavigate();
    
    return <>
         <div style={{ padding: "1rem", maxWidth: '400px', margin: "0 auto" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h1 className="header-text center-align">Sucess!</h1>
          <p className="grey=text center-align">Successfully responded to invitation. If you need to make a change you can using the invite email you received for 24 hours.</p>
          <Button onClick={() => navigate('/')}
            type="button"
            id="login-button"
            style={{ width: "100%", marginTop: "50px" }}>
             Home
          </Button>
        </Grid>
      </Grid>
    </div>
    </>
}

export default SuccessRespondedToInvitiation;