import React, {useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import config from '../global/config';
import {Grid, Button, TextField } from '@mui/material';
import { useNavigate } from "react-router-dom";



const ProjectInvitation = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const queryString = useMemo(() => new URLSearchParams(search), [search]);
  const action = queryString.get("action");
  const inviteCode = queryString.get("inviteCode");
  const [instructorNotes, setInstructorNotes] = useState("");

  let content = action === "1" ?
  {
    header: "Accept",
    bodyText1: "You're choosing to accept this project assignment.",
    submitEndpoint: config.baseUrl + "/Command/Instructor/Invite/" + inviteCode + "/Accept"
  } : {
    header: "Decline",
    bodyText1: "You're choosing to decline this project assignment.",
    submitEndpoint: config.baseUrl + "/Command/Instructor/Invite/" + inviteCode + "/Decline" 
  };

  let handleSubmit = () => {
    console.log(content.submitEndpoint)
    axios.post(content.submitEndpoint, {
        instructorNotes: instructorNotes
    })
    .then((response) => {
        navigate("/invitation/success");
    })
    .catch((error) => {
      navigate("/invitation/failed");
    })
  }

  return (
    <div style={{ padding: "1rem", maxWidth: '400px', margin: "0 auto" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h1 className="header-text center-align">{`${content.header} Assignment`}</h1>
          <p className="grey=text center-align">{content.bodyText1}</p>
            <TextField
            onChange={(event) => setInstructorNotes(event.target.value)}
            style={{width:"100%"}}
            id="standard-multiline-static"
            label="Notes to Admin"
            value={instructorNotes}
            multiline
            rows={4}
            variant="standard"
            />
          <Button onClick={handleSubmit}
            type="button"
            id="login-button"
            style={{ width: "100%", marginTop: "50px" }}>
            CONFIRM
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProjectInvitation;