import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Box, Button, DialogActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ProjectModal = (props) => {
  const navigate = useNavigate();    
    return(<>
  <Dialog
    open={props.open}
    onClose={props.handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  ><br></br>
  <Box p="1rem">

    <DialogTitle id="alert-dialog-title">
      {props.selectedProject.name} 

    </DialogTitle>
    <DialogContent>
    <IconButton
      aria-label="close"
      onClick={props.handleClose}
      sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        color: (theme) => theme.palette.grey[500],
      }}
      >
      <CloseIcon />
    </IconButton>
      <DialogContentText id="alert-dialog-description">
        <span className='header-text left-align'>Start Date:</span><br/>
        <span className="grey-text">{new Date(props.selectedProject.startDate).toLocaleDateString("en-US")}</span><br/><br/>
        <span className='header-text left-align'>End Date:</span> <br/>
        <span className="grey-text">{new Date(props.selectedProject.endDate).toLocaleDateString("en-US")}</span>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button id='project-details-modal-button' onClick={() => navigate(`/projects/${props.selectedProject.id}`, {state: {project: props.selectedProject}})}>Details</Button>
    </DialogActions>
      </Box>
  </Dialog></>)
}


export default ProjectModal;