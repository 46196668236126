import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    projects: [],
    loading: false,
}

export const projectSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
      startLoading: (state) => {
        state.loading = true;
      },
      loadProjects: (state, action) => {
        state.projects = action.payload;
        state.loading = false;
      },
    },
  });

export const { startLoading, loadProjects } = projectSlice.actions;

export default projectSlice.reducer;