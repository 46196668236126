import React from 'react'
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <Grid container alignItems="center" justifyContent="center" direction="column">
            <Grid item xs={12} className="">
                <div className="not-found-image-block">
                <svg xmlns="http://www.w3.org/2000/svg" width="243" height="103" viewBox="0 0 243 103" fill="none">
                    <path d="M209.463 81.16H166.203V63.52L206.663 1.92004H229.623V63.52H242.363V81.16H229.623V101.04H209.463V81.16ZM209.463 29.36H209.183L187.203 63.52H209.463V29.36Z" fill="#EA710B"/>
                    <path d="M84.6116 51.48C84.6116 45.6 85.1249 39.6267 86.1516 33.56C87.2716 27.4933 89.1849 21.9867 91.8916 17.04C94.6916 12.0933 98.4716 8.07998 103.232 4.99998C107.992 1.82665 114.058 0.23999 121.432 0.23999C128.805 0.23999 134.872 1.82665 139.632 4.99998C144.392 8.07998 148.125 12.0933 150.832 17.04C153.632 21.9867 155.545 27.4933 156.572 33.56C157.692 39.6267 158.252 45.6 158.252 51.48C158.252 57.36 157.692 63.3333 156.572 69.4C155.545 75.4667 153.632 80.9733 150.832 85.92C148.125 90.8667 144.392 94.9267 139.632 98.1C134.872 101.18 128.805 102.72 121.432 102.72C114.058 102.72 107.992 101.18 103.232 98.1C98.4716 94.9267 94.6916 90.8667 91.8916 85.92C89.1849 80.9733 87.2716 75.4667 86.1516 69.4C85.1249 63.3333 84.6116 57.36 84.6116 51.48ZM105.612 51.48C105.612 54.0933 105.705 57.22 105.892 60.86C106.172 64.5 106.825 68 107.852 71.36C108.878 74.72 110.418 77.5667 112.472 79.9C114.618 82.2333 117.605 83.4 121.432 83.4C125.165 83.4 128.105 82.2333 130.252 79.9C132.398 77.5667 133.985 74.72 135.012 71.36C136.038 68 136.645 64.5 136.832 60.86C137.112 57.22 137.252 54.0933 137.252 51.48C137.252 48.8667 137.112 45.74 136.832 42.1C136.645 38.46 136.038 34.96 135.012 31.6C133.985 28.24 132.398 25.3933 130.252 23.06C128.105 20.7267 125.165 19.56 121.432 19.56C117.605 19.56 114.618 20.7267 112.472 23.06C110.418 25.3933 108.878 28.24 107.852 31.6C106.825 34.96 106.172 38.46 105.892 42.1C105.705 45.74 105.612 48.8667 105.612 51.48Z" fill="#EA710B"/>
                    <path d="M43.76 81.16H0.5V63.52L40.96 1.92004H63.92V63.52H76.66V81.16H63.92V101.04H43.76V81.16ZM43.76 29.36H43.48L21.5 63.52H43.76V29.36Z" fill="#EA710B"/>
                </svg>
                    <svg className='gear-1' xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                      <path d="M26.3953 27.3611L31.0045 19.9338L28.7273 18.5251C28.0621 18.1103 27.6943 17.3511 27.7647 16.5685C27.8039 16.138 27.8195 15.6998 27.8039 15.2615C27.7882 14.4788 28.1951 13.7431 28.8916 13.3753L31.2471 12.1074L27.1152 4.39844L24.7598 5.66632C24.0711 6.03416 23.2338 5.97154 22.5921 5.52543C22.2321 5.27499 21.8643 5.04803 21.4809 4.83672C20.7923 4.46105 20.3619 3.74102 20.3853 2.95055L20.4714 0.273929L11.7382 0L11.6599 2.68446C11.6365 3.47493 11.1513 4.15582 10.4392 4.49236C10.2435 4.58627 10.0557 4.68019 9.86791 4.78193C9.6801 4.88367 9.49228 4.99324 9.3123 5.10281C8.63931 5.50979 7.80981 5.53327 7.14465 5.11847L4.85179 3.70189L0.242589 11.1291L2.5198 12.5457C3.18496 12.9605 3.55276 13.7197 3.48233 14.5023C3.4432 14.9328 3.42755 15.371 3.4432 15.8015C3.45885 16.5841 3.05193 17.312 2.35546 17.6877L0 18.9555L4.13184 26.6645L6.48731 25.3967C7.17595 25.0288 8.01327 25.0914 8.65496 25.5375C9.01493 25.788 9.38273 26.0149 9.76618 26.2184C10.4548 26.5941 10.8852 27.3141 10.8617 28.1046L10.7757 30.789L19.5089 31.063L19.595 28.3785C19.6184 27.5959 20.1036 26.915 20.8079 26.5784C21.0035 26.4845 21.1913 26.3906 21.387 26.2889C21.5748 26.1871 21.7626 26.0776 21.9426 25.968C22.6156 25.561 23.4529 25.5375 24.1259 25.9523L26.4031 27.3611H26.3953ZM19.0002 21.8435C15.5179 23.714 11.1826 22.407 9.3123 18.9164C7.44201 15.4337 8.74887 11.09 12.2312 9.21949C15.7135 7.34899 20.0567 8.66382 21.9269 12.1466C23.7972 15.6293 22.4825 19.973 19.0002 21.8357V21.8435Z" fill="#EDCD1F"/>
                    </svg>
                    <svg className='gear-2' xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                      <path d="M19.4288 20.259L22.8214 14.7596L21.1453 13.7166C20.6556 13.4094 20.3849 12.8473 20.4368 12.2678C20.4656 11.9491 20.4771 11.6246 20.4656 11.3001C20.454 10.7206 20.7536 10.1759 21.2662 9.9035L23 8.96473L19.9587 3.25674L18.2249 4.19552C17.718 4.46788 17.1017 4.42152 16.6294 4.09121C16.3644 3.90577 16.0937 3.73772 15.8114 3.58125C15.3045 3.3031 14.9877 2.76997 15.005 2.18468L15.0684 0.202823L8.64012 0L8.58252 1.98765C8.56524 2.57294 8.20811 3.0771 7.68395 3.32628C7.53994 3.39582 7.4017 3.46536 7.26345 3.54069C7.12521 3.61602 6.98698 3.69715 6.85449 3.77828C6.35913 4.07962 5.74856 4.097 5.25896 3.78987L3.57126 2.74099L0.178561 8.24036L1.85475 9.28924C2.34435 9.59637 2.61508 10.1585 2.56324 10.738C2.53444 11.0567 2.52291 11.3812 2.53443 11.6999C2.54595 12.2794 2.24644 12.8183 1.73379 13.0965L0 14.0353L3.04132 19.7433L4.77511 18.8045C5.282 18.5321 5.89832 18.5785 6.37065 18.9088C6.63561 19.0942 6.90634 19.2623 7.18859 19.413C7.69548 19.6911 8.01228 20.2242 7.995 20.8095L7.93163 22.7972L14.3599 23L14.4232 21.0123C14.4405 20.4329 14.7976 19.9287 15.3161 19.6795C15.4601 19.61 15.5983 19.5404 15.7423 19.4651C15.8805 19.3898 16.0188 19.3086 16.1513 19.2275C16.6466 18.9262 17.263 18.9088 17.7583 19.2159L19.4345 20.259H19.4288ZM13.9855 16.1736C11.4222 17.5586 8.23115 16.5908 6.85449 14.0063C5.47783 11.4276 6.43977 8.21139 9.00301 6.82641C11.5662 5.44142 14.7631 6.41497 16.1397 8.9937C17.5164 11.5724 16.5487 14.7886 13.9855 16.1678V16.1736Z" fill="#EDCD1F"/>
                    </svg>
                    <svg className='gear-3' xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none">
                        <path d="M22.2391 8.28553L19.1794 2.97923L17.5595 3.9184C17.0822 4.19232 16.4952 4.16884 16.0335 3.86361C15.7753 3.69926 15.5171 3.54273 15.2432 3.40185C14.7502 3.15141 14.4371 2.65835 14.4371 2.1105V0.23999H8.30981V2.11833C8.30981 2.66617 7.99679 3.15924 7.50379 3.40968C7.22989 3.55056 6.96383 3.69926 6.71342 3.87144C6.25172 4.16884 5.66481 4.20015 5.18746 3.92622L3.56759 2.98706L0.507827 8.29335L2.13552 9.23252C2.61288 9.50644 2.87894 10.0308 2.84764 10.5865C2.84764 10.7352 2.83982 10.8839 2.83982 11.0404C2.83982 11.1969 2.83981 11.3378 2.84764 11.4943C2.87894 12.0422 2.61288 12.5666 2.13552 12.8405L0.5 13.7875L3.55976 19.0938L5.18746 18.1546C5.66481 17.8807 6.25172 17.9042 6.71342 18.2094C6.96383 18.3737 7.22989 18.5303 7.50379 18.6633C7.99679 18.9138 8.30981 19.4068 8.30981 19.9547V21.833H14.4371V19.9547C14.4371 19.4068 14.7502 18.9138 15.2432 18.6633C15.5171 18.5225 15.7753 18.3737 16.0335 18.2094C16.4952 17.9042 17.0822 17.8807 17.5595 18.1546L19.1872 19.0938L22.247 13.7875L20.6193 12.8483C20.1419 12.5744 19.8837 12.05 19.9071 11.5022C19.9071 11.3535 19.915 11.1969 19.915 11.0482C19.915 10.8995 19.915 10.7508 19.9071 10.5943C19.8758 10.0465 20.1419 9.5221 20.6193 9.24035L22.247 8.30118L22.2391 8.28553ZM16.3857 11.0248C16.3857 13.7953 14.1398 16.0415 11.3696 16.0415C8.59935 16.0415 6.35345 13.7953 6.35345 11.0248C6.35345 8.25422 8.59935 6.00804 11.3696 6.00804C14.1398 6.00804 16.3857 8.25422 16.3857 11.0248Z" fill="#EDCD1F"/>
                    </svg>
                </div>
                
                <h1 className='text-center title-404'>Ooops!</h1>
                <p className='text-center subtitle-404'>This page cannot be found.</p>
                <Link  id="back-home" style={{marginTop: "40px"}} to="/projects">
                BACK TO HOME
                </Link>
            </Grid>
        </Grid>
    );
}

export default NotFound;
