import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: [],
    loading: false
}

export const unavailableDaysSlice = createSlice({
    name: 'unavailableDays',
    initialState,
    reducers: {
        loadUnavailableDays: (state, action) => {
            state.value = action.payload
        },
        removeUnavailableDay: (state, action) => {
            state.value = [...state.value.filter(s => new Date(s.date).toDateString() != new Date(action.payload).toDateString())]
        },
        addUnavailableDay: (state, action) => {
            state.value = [...state.value, {date: action.payload}]
        },
        setLoading:(state)=>{
            state.loading=true
        },
        removeLoading:(state)=>{
            state.loading=false
        }
    }
})

// Action creators are generated for each case reducer function
export const { setLoading, removeLoading, loadUnavailableDays, removeUnavailableDay, addUnavailableDay } = unavailableDaysSlice.actions

export default unavailableDaysSlice.reducer
