import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";

const options = ["Log out"];

const ITEM_HEIGHT = 48;

export default function HamburgerMenu() {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const theme = useTheme();
	const navigate = useNavigate();

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleExit = () => {
		localStorage.clear();
		setAnchorEl(null);
		navigate("/");
	};

	return (
		<div>
			<IconButton
				aria-label="more"
				id="hamburger-button"
				aria-controls={anchorEl ? "long-menu" : undefined}
				aria-expanded={anchorEl ? "true" : undefined}
				aria-haspopup="true"
				onClick={handleClick}
				color="primary">
				<MenuIcon />
			</IconButton>
			<Menu
				id="long-menu"
				MenuListProps={{
					"aria-labelledby": "hamburger-button",
				}}
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				PaperProps={{
					style: {
						maxHeight: ITEM_HEIGHT * 4.5,
						width: "20ch",
					},
				}}>
				{options.map((option) => (
					<MenuItem
						key={option}
						selected={option === "Pyxis"}
						style={{ color: theme.palette.error.main }}
						onClick={handleExit}>
						{option}
					</MenuItem>
				))}
			</Menu>
		</div>
	);
}
