import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Slide,
  Typography,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UsageInstruction({ open, handleClose }) {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        sx: {
          margin: '1rem',
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{padding: "1rem"}}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap="10px"
          id="alert-dialog-slide-description"
          sx={{
            p: { xs: '0', sm: '0.825rem' }, }}
        >
          <Box display="flex" gap="10px" alignItems="center">
            <div className="unavailable-icon"></div>
            <div>-</div>
            <Typography>Unavailable date</Typography>
          </Box>
          <Box display="flex" gap="10px" alignItems="center">
            <div className="project-icon"></div>
            <div>-</div>
            <Typography>One project at the specific date</Typography>
          </Box>
          <Box display="flex" gap="10px" alignItems="center">
            <div className="projects-icon"></div>
            <div>-</div>
            <Typography>Multiple projects at the specific date</Typography>
          </Box>
          <Box display="flex" gap="10px" alignItems="center">
            <div className="selected-icon"></div>
            <div>-</div>
            <Typography>Selected day(s)</Typography>
          </Box>
          <Box display="flex" gap="10px" alignItems="center">
            <div className="unavailable-project-icon"></div>
            <div>-</div>
            <Typography>
              Unavailability and one project at the same specific date
            </Typography>
          </Box>
          <Box display="flex" gap="10px" alignItems="center">
            <div className="unavailable-projects-icon"></div>
            <div>-</div>
            <Typography>
              Unavailability and multiple projects at the same specific date
            </Typography>
          </Box>

          <Divider component="p" />
          <Typography variant="h6" component="p">
            1) One click by date:{" "}
          </Typography>
          <Box pl="1rem">
            <Typography>Chooses the "Start date" of the range</Typography>
          </Box>
          <Divider component="p" />
          <Typography variant="h6" component="p">
            2) Second click on the next day or any later day:
          </Typography>
          <Box pl="1rem">
            <Typography>
              Selects the "End date" of the range and open manage availability
              modal window
            </Typography>
          </Box>
          <Divider component="p" />
          <Typography variant="h6" component="p">
            3) Double click (long press on touch device) by one date:
          </Typography>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box display="flex" gap="4px" p="3px" justifyContent="flex-end">
                <div className="unavailable-icon"></div>
                <div className="empty-icon"></div>
              </Box>
            </Grid>
            <Grid item xs={12} md={10}>
              <Typography sx={{ fontWeight: "1rem" }}>
                There are no projects at the specific date - opens manage
                availability modal window{" "}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box display="flex" gap="4px" p="3px" justifyContent="flex-end">
                <div className="project-icon"></div>
              </Box>
            </Grid>
            <Grid item xs={12} md={10}>
              <Typography>
                There is only one project at the specific date - opens project's
                info
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box display="flex" gap="4px" p="3px" justifyContent="flex-end">
                <div className="projects-icon"></div>
                <div className="unavailable-project-icon"></div>
                <div className="unavailable-projects-icon"></div>
              </Box>
            </Grid>
            <Grid item xs={12} md={10}>
              <Typography>
                There is more than one project at the specific date or
                unavailable date intersects with at least one project - opens
                dropdown with ability to select desired project or manage
                availability
              </Typography>
            </Grid>
            {/* </Box> */}
            {/* </Box> */}
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
