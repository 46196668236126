import { useNavigate } from "react-router-dom";
import {Grid, Button } from '@mui/material';


const FailedRespondedToInvitation = () => {
    const navigate = useNavigate();

    return <>
      <div style={{ padding: "1rem", maxWidth: '400px', margin: "0 auto" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h1 className="header-text center-align">Something went wrong.</h1>
          <p className="grey=text center-align">Failed to respond to invitation. Please contact DLTS Admins for a new invite.</p>
          <Button onClick={() => navigate('/')}
            type="button"
            id="login-button"
            style={{ width: "100%", marginTop: "50px" }}>
             Home
          </Button>
        </Grid>
      </Grid>
    </div>
    </>
}

export default FailedRespondedToInvitation;