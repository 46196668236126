import React, { useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import config from '../global/config';
import { Select, MenuItem, TextareaAutosize, Button, FormControl, InputLabel, Box } from '@mui/material';
import LoadingIcon from '../Icons/LoadingIcon';

const recordFeedbackUrl = '/command/survey';

const SurveyFeedback = () => {
  const [isComplete, setIsComplete] = useState(false);
  const { search } = useLocation();
  const queryString = useMemo(() => new URLSearchParams(search), [search]);
  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleResponseChange = (id, value) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((question) =>
        question.id === id ? { ...question, userResponse: value } : question
      )
    );
  };
  const areAllQuestionsAnswered = questions.every((question) => question.userResponse?.trim());

  const completeSurveyFeedback = () => {
    axios
      .post(config.baseUrl + recordFeedbackUrl, {
        surveyCode: queryString.get('surveyCode'),
        email: queryString.get('email'),
        projectId: queryString.get('projectId'),
        answers: questions,
      })
      .then(() => {
        setIsComplete(true);
      })
      .catch(() => {
        alert('Error retrieving survey. Please contact DLTS support.')
      });
  };

  useEffect(() => {
    axios
      .get(config.baseUrl + '/query/survey/' + queryString.get('surveyCode'))
      .then((response) => {
        setQuestions(response.data.userQuestions);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false); // Ensure loading stops even if there's an error
      });
  }, [queryString]);

  const message =
    'Thank you for providing your feedback on your recent training experience. We strive to provide the best service, and will review your feedback for opportunities for improvement.';

  if (!isComplete) {
    return (
      <>
        <section className="body_section">
          <div className="container">
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              style={{ maxWidth: '600px', margin: '0 auto' }}
            >
              {isLoading ? (
                <LoadingIcon /> // Show the local LoadingIcon component
              ) : (
                <form>
                    <h1>Survey Feedback</h1>
                  {questions.map((question) => (
                    <div key={question.id} style={{ marginBottom: '20px', width: '100%' }}>
                      <label htmlFor={`question-${question.id}`}>
                        <strong>{question.questionText}</strong>
                      </label>
                      {question.questionType === 2 ? (
                        <TextareaAutosize
                          id={`question-${question.id}`}
                          minRows={4}
                          placeholder="Enter your response here..."
                          value={question.userResponse || ''}
                          onChange={(e) => handleResponseChange(question.id, e.target.value)}
                          style={{
                            display: 'block',
                            marginTop: '10px',
                            width: '100%',
                            maxWidth: '100%',
                            padding: '10px',
                            borderRadius: '4px',
                            border: '1px solid #ccc',
                          }}
                        />
                      ) : (
                        <FormControl fullWidth style={{ marginTop: '10px' }}>
                          <InputLabel id={`question-${question.id}-label`}>Select an option</InputLabel>
                          <Select
                            id={`question-${question.id}`}
                            value={question.userResponse || ''}
                            onChange={(e) => handleResponseChange(question.id, e.target.value)}
                            labelId={`question-${question.id}-label`}
                          >
                            {question.answerChoices.map((choice, index) => (
                              <MenuItem key={index} value={choice}>
                                {choice}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </div>
                  ))}
                  {!isLoading && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={completeSurveyFeedback}
                      style={{ cursor: 'pointer', marginTop: '20px' }}
                      disabled={!areAllQuestionsAnswered}
                    >
                      Complete Survey Feedback
                    </Button>
                  )}
                </form>
              )}
            </Box>
          </div>
        </section>
      </>
    );
  }

  return (
    <>
      <section className="body_section">
        <div className="container">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            style={{ maxWidth: '600px', margin: '0 auto' }}
          >
            <img src="/thank_you1.svg" alt="Thank you" style={{ marginBottom: '20px' }} />
            <p>{message}</p>
          </Box>
        </div>
      </section>
    </>
  );
};

export default SurveyFeedback;
