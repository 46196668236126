import React, { useEffect, useState } from "react";
import BottomNav from "../Nav/BottomNav";
import ProjectModal from "./ProjectModal";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Calendar from "./Calendar";
import axios from "axios";
import config from "../global/config";
import { useDispatch } from "react-redux";
import { setLoading, removeLoading } from "./unavailableDaysSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import AvailabilityModalProxy from "./AvailabilityModalProxy";
import LoadingIcon from "../Icons/LoadingIcon";
import { Box, Container } from "@mui/material";
import Button from "@mui/material/Button";
import UsageInstruction from "./UsageInstruction";

const Schedule = () => {
  const dispatch = useDispatch();

  const projects = useSelector((state) =>
    state.projects.projects.flatMap((p) => {
      var getDaysArray = getDaysInBetween(p.startDate, p.endDate);
      return getDaysArray.map((d) => {
        return {
          day: d.getDate(),
          month: d.getMonth() == 12 ? 1 : d.getMonth() + 1,
          year: d.getFullYear(),
          name: p.name,
          completed: false,
          color: "#EA710B",
          startDate: new Date(p.startDate).toLocaleDateString("en-US"),
          endDate: new Date(p.endDate).toLocaleDateString("en-US"),
          project: p,
        };
      });
    })
  );

  const getUnavailableDates = () => {
    axios
      .get(config.baseUrl + "/query/instructors/unavailability", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const days = response.data.map((elem) => new Date(elem.date).getTime());
        setUnavailableDates(days);
      });
  };

  useEffect(() => {
    getUnavailableDates();
  }, []);

  const [selectedProject, setSelectedProject] = useState({});
  const [projectModalOpen, setProjectModalOpen] = useState(false);
  const [availabilityModalOpen, setAvailabilityModalOpen] = useState(false);
  const [dayStatusDropdownOpen, setDayStatusDropdownOpen] = useState(-1);
  const [selectedDate, setSelectedDate] = useState([]);
  const [unavailableDates, setUnavailableDates] = useState([]);
  const isLoading = useSelector((state) => state.unavailableDays.loading);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  var token = localStorage.getItem("instructorToken");

  if (!token) {
    return <Navigate to="/" />;
  }

  function getDaysInBetween(start, end) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(new Date(dt));
    }
    return arr;
  }

  function handleClick(datas) {
    const data = datas[0];
    data.available = true;
    setSelectedDate(data);
    setAvailabilityModalOpen(true);
  }

  const handleSetAvailabilityClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDayStatusDropdownOpen(-1);
    setAvailabilityModalOpen(true);
  };

  const handleSetSingleProjectClick = (data, project) => {
    setSelectedProject(project.project);
    setProjectModalOpen(true);
    setDayStatusDropdownOpen(-1);
  };

  const handleDoubleClick = (date, status, uniqueProjects) => {
    const { isUnavailable, isProject } = status;
      setSelectedDate([date, date]);
    if (isProject && isUnavailable) {
      setAvailabilityModalOpen(false);
      setDayStatusDropdownOpen(date);
    } else if (isProject) {
      if (uniqueProjects.length > 1) {
        setAvailabilityModalOpen(false);
        setDayStatusDropdownOpen(date);
      } else {
        setSelectedProject(uniqueProjects[0].project);
        setAvailabilityModalOpen(false);
        setProjectModalOpen(true);
      }
    } else {
      setAvailabilityModalOpen(true);
    }
  };

  function handleSubmit(changedDates) {
    let url = config.baseUrl + "/command/instructor/changeAvailability";
    const healthUrl = config.baseUrl + "/query/health";

    dispatch(setLoading());
    axios
      .post(
        url,
        { Date: changedDates },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((response) => {
        toast.success("Successfully Updated Availability.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        getUnavailableDates();
        setSelectedDate([null, null]);
      })
      .catch((error) => {
        toast.error("Failed to Update Availability.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });

        axios
          .get(healthUrl, { headers: { Authorization: `Bearer ${token}` } })
          .catch((ex) => {
            localStorage.removeItem("instructorToken");
            return <Navigate to="/" />;
          });
      })
      .finally(() => {
        dispatch(removeLoading());
      });
    setAvailabilityModalOpen(false);
  }

  return (
    <Container style={{ marginBottom: "150px" }}>
      <Box
        display="flex"
        flexDirection="column"
        gap={{ xs: "2rem", md: "8rem" }}
      >
        <Box
          display="flex"
          sx={{
            flexDirection: {
              xs: "column-reverse",
              sm: "row-reverse",
            },
            justifyContent: {
              xs: "center",
              sm: "space-between",
            },
            gap: "2rem",
          }}
        >
          <Box
            variant="h4"
            component="h1"
            className="header-text"
            style={{ textAlign: "left" }}
          >
            Schedule
          </Box>
          <>
            <Button variant="outlined" onClick={handleClickOpen}>
              Calendar usage instruction
            </Button>
            <UsageInstruction open={open} handleClose={handleClose} />
          </>
        </Box>
        <Box
          display="flex"
          pt="3rem"
          sx={{ margin: "auto", width: "fit-content" }}
          className="calendar-container"
        >
          <Box style={{ margin: "auto", width: "fit-content" }}>
            <Calendar
              handleClickDay={handleClick}
              handleDoubleClick={handleDoubleClick}
              unavailableDates={unavailableDates}
              projects={projects}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              dayStatusDropdownOpen={dayStatusDropdownOpen}
              setDayStatusDropdownOpen={setDayStatusDropdownOpen}
              handleSetAvailabilityClick={handleSetAvailabilityClick}
              handleSetSingleProjectClick={handleSetSingleProjectClick}
            />
          </Box>
        </Box>
        {isLoading && <LoadingIcon />}
        <ToastContainer />
        <BottomNav activePage="schedule"></BottomNav>
        <ProjectModal
          handleClose={() => setProjectModalOpen(false)}
          selectedProject={selectedProject}
          open={projectModalOpen}
        ></ProjectModal>
        <AvailabilityModalProxy
          type={selectedDate[1] ? "range" : "single"}
          handleSubmit={handleSubmit}
          handleClose={() => setAvailabilityModalOpen(false)}
          open={availabilityModalOpen}
          dateData={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      </Box>
    </Container>
  );
};

export default Schedule;
