import React from 'react';

const StatusIconText = (props) => {
    if(props.projectStatus.toUpperCase() === "INPROGRESS") {
        return(<>
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="5" cy="5" r="5" fill="#FFC700"/>
            </svg>
            <span className={"text light-grey-text"}>&nbsp;In Progress</span>
        </>)
    } else if(props.projectStatus.toUpperCase() === "COMPLETED") {
        return (<>
            <svg className="icon" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="5" cy="5" r="5" fill="#1FED65"/>
            </svg>
             <span className={"text light-grey-text"}>&nbsp;Completed</span>
        </>)
    } else {
        return (<>
            <svg className="icon" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="5" cy="5" r="5" fill="#1FED65"/>
            </svg>
             <span className={"text light-grey-text"}>&nbsp;Upcoming</span>
        </>)
    }
}

export default StatusIconText;