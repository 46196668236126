/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useState } from "react";
import ReactDOM from "react-dom";
import { useLocation, useNavigate } from "react-router-dom";
import BackIconText from "../Icons/BackIconText";
import LocationIcon from "../Icons/LocationIcon";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import {
	Grid,
	Typography
} from "@mui/material";
import BottomNav from "../Nav/BottomNav";
import DeleteIcon from "../Icons/DeleteIcon";
import config from "../global/config";
import DeleteTraineeFormModal from "./DeleteTraineeFormModal";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import ModalForm from "../pdf/ModalForm";

const ProjectDetails = () => {
	const { state } = useLocation();
	const [project, setProject] = useState(state.project);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [form, setForm] = useState(null);
	const [noteContent, setNoteContent] = useState("");
	const [loaded, setLoaded] = useState(false);
	const [access, setAccess] = useState(false);
	const navigate = useNavigate();
	const token = localStorage.getItem("instructorToken");
	const [buttonType, setButtonType] = useState("");
	// const [currentTab, setCurrentTab] = useState("Upload");
	const [invalidSubmit, setInvalidSubmit] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [isNotesEmpty, setIsNotesEmpty] = useState(false);

	let locationCity = project?.locationCity ? project.locationCity + "," : "";

	const openModal = () => {
		setShowModal(true);
	};

	const closeModal = () => {
		setShowModal(false);
	};

	useEffect(() => {
		const fetchNote = async () => {
			let url =
				config.baseUrl +
				`/query/instructors/getNotes?projectId=${state.project.id}`;
			const res = await axios.get(url, {
				headers: {
					Authorization: "Bearer " + token,
				},
			});
			setNoteContent(res.data);
			setLoaded(true);
			if (res.data.toString().length > 0) {
				setButtonType("update");
			} else {
				setButtonType("create");
			}
		};
		const fetchAccess = async () => {
			let url =
				config.baseUrl +
				`/query/instructors/checkInstructorProjectAssignment?projectId=${state.project.id}`;
			const res = await axios.get(url, {
				headers: {
					Authorization: "Bearer " + token,
				},
			});
			setAccess(res.data);
		};
		fetchAccess();
		fetchNote();
	}, []);

	function openDeleteModal(form) {
		setForm(form);
		setDeleteModalOpen(true);
	}

	function removeDeletedFile(formId) {
		console.log(formId);
	}

	const postMessage = () => {
		document.getElementById("inner").contentWindow.postMessage(
			{
				token: token,
				projectId: state.project.id,
				traineeId: state.trainee.id,
			},
			"*"
		);
	};

	const submitFile = () => {
		if (noteContent.length > 0) {
			setIsNotesEmpty(true);
		} else setIsNotesEmpty(false);
		setInvalidSubmit(false);
		let data = {
			notes: noteContent.replace(/^\s+|\s+$/g, ""),
			projectId: state.project.id,
		};
		let url =
			config.baseUrl +
			`/command/instructor/${
				buttonType === "update" ? "editNotes" : "addNotes"
			}`;
		let urlDelete =
			config.baseUrl +
			`/command/instructor/deleteNotes?projectId=${state.project.id}`;
		if (buttonType === "create") {
			axios.post(url, data, {
				headers: {
					Authorization: "Bearer " + token,
				},
			});
			toast.success("Successfully added notes", {
				position: toast.POSITION.BOTTOM_CENTER,
				autoClose: 1000,
			});
			setButtonType("update");
		} else if (noteContent.length === 0 && buttonType === "update") {
			axios.delete(urlDelete, {
				headers: {
					Authorization: "Bearer " + token,
				},
			});
			toast.success("Successfully deleted notes", {
				position: toast.POSITION.BOTTOM_CENTER,
				autoClose: 1000,
			});
			setButtonType("create");
		} else {
			axios.put(url, data, {
				headers: {
					Authorization: "Bearer " + token,
				},
			});
			toast.success("Successfully updated notes", {
				position: toast.POSITION.BOTTOM_CENTER,
				autoClose: 1000,
			});
		}
		closeModal();
	};

	window.addEventListener("message", function (event) {
		if (event.data) {
			if (event.data === "SEND_DATA_BACK") {
				postMessage();
			}
		}
	});

	return (
		<>
			<h3 style={{ cursor: "pointer" }} onClick={() => navigate("/projects")}>
				<BackIconText text={"Back to all Projects"}></BackIconText>
			</h3>
			<div
				style={{
					margin: "0 auto",
					maxWidth: "1200px",
					marginTop: "25px",
					marginBottom: "100px",
					padding: "1rem",
				}}>
				<h2 className="header-text center-align">{project.name}</h2>

				<div>
					{project.notes && (
						<div style={{overflowWrap: 'break-word'}}>
							<p className="header-text left-align">Admin Notes:</p>
							<p>{project.notes}</p>
						</div>
					)}

					{project.instructorNotes.length > 0 && (
						<div style={{overflowWrap: 'break-word'}}>
							<p className="header-text left-align">
								Instructor Timesheet Notes:
							</p>
							{project.instructorNotes.map((note) => {
								return (
									<p>
										{note.formattedDateCreated} {note.userName}: {note.notes}
									</p>
								);
							})}
						</div>
					)}
				</div>
				<Grid container spacing={2} justifyContent={"space-between"}>
					<Grid item xs={12} md={4}>
						<p
							className="header-text left-align"
							style={{ display: "flex", gap: "8px", alignItems: "center" }}>
							<PermContactCalendarIcon />
							<span>Contact Info:</span>
						</p>
						<p>{project.contactName}</p>
						<p>{project.contactPhone}</p>
						<p>{project.contactEmail}</p>
					</Grid>
					<Grid item xs={12} md={4}>
						<p
							className="header-text left-align"
							style={{ display: "flex", gap: "8px", alignItems: "center" }}>
							<LocationIcon />
							<span>Training Location:</span>
						</p>
						<p>{project.locationAddress1}</p>
						<p>
							{locationCity} {project.locationState} {project.locationZipCode}
						</p>
					</Grid>
				</Grid>

				<p className="header-text left-align">Trainees:</p>
				{project.trainees.map((trainee, i) => {
					return (
						<div
							style={{
								backgroundColor: "#F7F7F9",
								marginTop: "10px",
								marginBottom: "25px",
								padding: "1rem",
							}}
							key={i}>
							<Grid container>
								<Grid item xs={12}>
									<h4 className={"header-text"} style={{ textAlign: "left" }}>
										{" "}
										<span className={"text"}>{trainee.title}</span>
									</h4>
									<p>Class: {trainee.class}</p>
									<p>
										{trainee.phoneNumber}
										<br />
										{trainee.email}
									</p>
									<p>Forms: </p>
									{trainee.forms.map((f, i) => {
										var formUrl = config.blobUrl + f.link;
										return (
											<>
												<a
													target="_blank"
													href={formUrl}
													key={i * 10}
													rel="noreferrer">
													{f.link}
												</a>
												&nbsp;&nbsp;{" "}
												<a onClick={() => openDeleteModal(f)}>
													<DeleteIcon />
												</a>
												<br />
												<br />
											</>
										);
									})}

									{access && (
										<div
											style={{
												display: "flex",
												flexDirection: "column",
												gap: "1rem",
												alignItems: "flex-start",
											}}>
											<a
												style={{ cursor: "pointer", color: "#0069c2" }}
												className="grey-text"
												onClick={() =>
													navigate(`/trainee-form`, {
														state: { project: project, trainee: trainee },
													})
												}>
												Add new form
											</a>
										</div>
									)}
								</Grid>
							</Grid>
						</div>
					);
				})}

				<div style={{overflowWrap: 'break-word'}}>
					<Typography variant="h5">My Project Notes:</Typography>
					<Typography variant="body1">
								{noteContent}
					</Typography>
					<a style={{ cursor: "pointer", color: "#0069c2" }}
												className="grey-text" onClick={openModal}>Add/Edit Project Notes</a>

				</div>

				<DeleteTraineeFormModal
					form={form}
					handleDeletedFile={(formId, traineeId) =>
						removeDeletedFile(formId, traineeId)
					}
					handleClose={() => setDeleteModalOpen(false)}
					open={deleteModalOpen}></DeleteTraineeFormModal>
				<ToastContainer />
			</div>
			<BottomNav />
			{showModal &&
				ReactDOM.createPortal(
					<ModalForm
						noteContent={noteContent}
						setNoteContent={setNoteContent}
						buttonType={buttonType}
						submitFile={submitFile}
						closeModal={closeModal}
					/>,
					document.body
				)}
		</>
	);
};

export default ProjectDetails;
