import React from 'react';

const BackIconText = (props) => {
    var text = props.text ? props.text : 'Back';
    return (<>
    <svg className={"icon"} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19 12H5" stroke="#2D3C47" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M12 19L5 12L12 5" stroke="#2D3C47" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <span className={"text light-grey-text"}>{text}</span>
          </>)
}


export default BackIconText;