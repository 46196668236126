import React from 'react';

const DownArrowIcon = () => {
    return (<>
    <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_187_602)">
        <path d="M6.5806 9.01908C6.8107 9.01908 7.04077 8.92259 7.2162 8.73L12.7366 2.66666C13.0878 2.28096 13.0878 1.6556 12.7366 1.27005C12.3856 0.884511 11.8163 0.884511 11.4651 1.27005L6.5806 6.63523L1.69603 1.27024C1.34486 0.884691 0.775672 0.884691 0.424672 1.27024C0.0733318 1.65579 0.0733318 2.28114 0.424672 2.66685L5.94501 8.7302C6.12053 8.92281 6.35059 9.01908 6.58061 9.01908H6.5806Z" fill="#3A3A3A"/>
        </g>
        <defs>
        <clipPath id="clip0_187_602">
        <rect width="12.8388" height="8.03819" fill="white" transform="matrix(-1 0 0 1 13 0.980896)"/>
        </clipPath>
        </defs>
    </svg>
    </>)
}

export default DownArrowIcon;