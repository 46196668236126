import React, { useState } from "react";
import BottomNav from "../Nav/BottomNav";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import YellowAddButton from "./YellowAddButton";
import DownArrowIcon from "../Icons/DownArrowIcon";
import UpArrowIcon from "../Icons/UpArrowIcon";
import EditIcon from "@mui/icons-material/Edit";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import LoadingIcon from "../Icons/LoadingIcon";
import { Button, Container } from "@mui/material";

const Timesheets = () => {
  const [expanded, setExpanded] = useState([]);
  const navigate = useNavigate();
  const projects = useSelector((state) => state.projects.projects);
  const isLoading = useSelector((state) => state.projects.loading);

  var token = localStorage.getItem("instructorToken");
  console.log(projects);

  if (!token) {
    return <Navigate to="/" />;
  }

  function expand(id) {
    setExpanded([...expanded, id]);
  }

  function unExpand(id) {
    var arr = Array.from(expanded);
    var index = arr.indexOf(id);
    if (index !== -1) {
      arr.splice(index, 1);
      setExpanded(arr);
    }
  }

  const arr = [];
  projects.forEach((project) =>
    project.timesheets
      .map((timesheet) => ({ ...timesheet, projectName: project.name }))
      .forEach((m) => {
        m.projectIsCompleted = project.isCompleted;
        return arr.push(m);
      })
  );

  const sortedArr = arr.sort((a, b) =>
    new Date(a.date) > new Date(b.date) ? -1 : 1
  );

  const handleClick = (t) => {
    const today = new Date();
    const tDate = new Date(t.date);
    if (t.projectIsCompleted && tDate < today) {
      return;
    } else {
      navigate(`/timesheets/${t.timesheetId}`, {
        state: { timesheet: t },
      });
    }
  };

  return (
    <div style={{ marginBottom: "175px" }}>
      <Container>
        <h1 className="header-text" style={{ textAlign: "left" }}>
          Your Timesheets
        </h1>
        <Grid container spacing={2}>
          {isLoading && <LoadingIcon />}
          {sortedArr.map((t, i) => {
            return (
              <Grid item xs={12} sm={6} key={i}>
                <Card>
                  <CardContent>
                    <div style={{ float: "right" }}>
                      <EditIcon
                        onClick={() => handleClick(t)}
                        sx={
                          t.projectIsCompleted &&
                          Date.parse(t.date) < Date.parse(new Date())
                            ? { cursor: "default" }
                            : { cursor: "pointer" }
                        }
                        color={
                          t.projectIsCompleted &&
                          Date.parse(t.date) < Date.parse(new Date())
                            ? "disabled"
                            : "primary"
                        }
                      ></EditIcon>
                    </div>
                    <Grid container>
                      <Grid item xs={12}>
                        <h3>
                          <span className="header-text left-align">
                            Project Name:
                          </span>{" "}
                          <span className="grey-text">{t.projectName}</span>
                        </h3>
                      </Grid>
                      <Grid item xs={12}>
                        <p className="header-text left-align">
                          Date:{" "}
                          <span className="grey-text">
                            {new Date(t.date).toDateString()}
                          </span>
                        </p>
                      </Grid>
                      <Grid item xs={12}>
                        <p className="header-text left-align">
                          Start/End Time:{" "}
                          <span className="grey-text">
                            {t.startTime} / {t.endTime}
                          </span>
                        </p>
                      </Grid>
                      <Grid item xs={12}>
                        <p
                          className={
                            expanded.includes(t.id)
                              ? "header-text left-align"
                              : "hidden"
                          }
                        >
                          Total Hours:{" "}
                          <span className="grey-text">{t.totalHours}</span>
                        </p>
                      </Grid>
                      <Grid item xs={12}>
                        <p
                          className={
                            expanded.includes(t.timesheetId)
                              ? "header-text left-align"
                              : "hidden"
                          }
                        >
                          Lead Instructor:{" "}
                          <span className="grey-text">
                            {t.leadInstructor ? "Yes" : "No"}
                          </span>
                        </p>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          onClick={() => expand(t.timesheetId)}
                          sx={{ display: "flex", gap: "6px" }}
                          className={
                            !expanded.includes(t.timesheetId)
                              ? "grey-text"
                              : "hidden"
                          }
                          style={{ float: "right", color: "#2d3c47" }}
                        >
                          Show Details <DownArrowIcon></DownArrowIcon>
                        </Button>
                        <Button
                          onClick={() => unExpand(t.timesheetId)}
                          sx={{ display: "flex", gap: "6px" }}
                          className={
                            expanded.includes(t.timesheetId)
                              ? "orange-text"
                              : "hidden"
                          }
                          style={{ float: "right", textDecoration: "none" }}
                        >
                          Hide Details <UpArrowIcon></UpArrowIcon>
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions></CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Container>

      <YellowAddButton></YellowAddButton>
      <BottomNav activePage="timesheets"></BottomNav>
    </div>
  );
};

export default Timesheets;
