import React, { useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { startLoading, loadProjects } from "../Projects/projectSlice";
import { loadUnavailableDays } from "../Schedule/unavailableDaysSlice";
import config from "../global/config";
const AuthorizedLayout = (props) => {
  const dispatch = useDispatch();
  var token = localStorage.getItem("instructorToken");
  useEffect(() => {
    dispatch(startLoading());
    axios
      .get(config.baseUrl + "/query/projects", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        dispatch(loadProjects(response.data));
      })
      .catch((error) => {
        localStorage.removeItem("instructorToken");
        window.location.href = "/";
      });
    axios
      .get(config.baseUrl + "/query/instructors/unavailability", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(loadUnavailableDays(response.data));
      });
  }, []);
  return <>{props.component}</>;
};

export default AuthorizedLayout;
