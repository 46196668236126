import React, { useState } from "react";
import config from "../global/config";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingIcon from "../Icons/LoadingIcon";
import BackIconText from "../Icons/BackIconText";
import { Grid } from "@mui/material";
import { Button } from "@mui/material";
import axios from "axios";
import { useSelector } from "react-redux";
import { loadProjects } from "../Projects/projectSlice";
import { useDispatch } from "react-redux";

const TraineeForm = (props) => {
  const projects = useSelector((state) => state.projects.projects);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState("Upload");
  const [invalidSubmit, setInvalidSubmit] = useState(false);
  const { state } = useLocation();
  const token = localStorage.getItem("instructorToken");
  const postMessage = () => {
    document.getElementById("inner").contentWindow.postMessage(
      {
        token: token,
        projectId: state.project.id,
        traineeId: state.trainee.id,
      },
      "*"
    );
  };

  const submitFile = () => {
    const selectedFile = document.getElementById("trainee-file-upload")
      .files[0];

    if (selectedFile) {
      setInvalidSubmit(false);
      var reader = new FileReader();

      reader.onload = function () {
        let data = {
          fileName: selectedFile.name,
          base64File: reader.result.split(",")[1],
          projectId: state.project.id,
          traineeId: state.trainee.id,
        };
        let url = config.baseUrl + "/command/instructor/saveForm";
        setIsLoading(true);
        axios
          .post(url, data, {
            headers: {
              Authorization: "Bearer " + token,
            },
          })
          .then((response) => {
            setIsLoading(false);

            toast.success("Successfully Submitted File.", {
              position: toast.POSITION.BOTTOM_CENTER,
            });

            axios
              .get(config.baseUrl + "/query/projects", {
                headers: { Authorization: `Bearer ${token}` },
              })
              .then((response) => {
                dispatch(loadProjects(response.data));
              });
          })
          .catch((error) => {
            setIsLoading(false);
            toast.error("Failed to submit file.", {
              position: toast.POSITION.BOTTOM_CENTER,
            });
          });
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
      reader.readAsDataURL(selectedFile);
      // axios.post.....

      // TODO: Submit to API
    } else {
      setInvalidSubmit(true);
      return;
    }
  };

  window.addEventListener("message", function (event) {
    if (event.data) {
      if (event.data === "SEND_DATA_BACK") {
        postMessage();
      }
    }
  });

  return (
    <div>
      <h3
        style={{ cursor: "pointer" }}
        onClick={() =>
          navigate(`/projects/${state.project.id}`, {
            state: { project: projects.find((p) => p.id == state.project.id) },
          })
        }
      >
        <BackIconText text={"Back"}></BackIconText>
      </h3>
      <Grid container>
        <Grid item xs={5}>
          <button
            className={currentTab === "Upload" ? "black-button" : "grey-button"}
            onClick={() => {
              setCurrentTab("Upload");
            }}
          >
            Upload Picture
          </button>
        </Grid>
        <Grid item xs={5}>
          <button
            className={currentTab === "Manuel" ? "black-button" : "grey-button"}
            onClick={() => setCurrentTab("Manuel")}
          >
            Manual Entry
          </button>
        </Grid>
      </Grid>

      {/* <h3 className={"header-text "}>Upload Picture</h3>

       <p style={{textAlign:"center"}}>
        <input type="file"/>
       </p>
        
        <h3 className={"header-text"}>Or</h3> */}

      {currentTab === "Upload" && (
        <div>
          <input
            id="trainee-file-upload"
            name="traineeFileUpload"
            type="file"
            style={{ marginTop: "50px", marginBottom: "40px" }}
          />
          <br />
          {invalidSubmit && (
            <p style={{ color: "red" }}>You must upload at least one file.</p>
          )}
          <Button
            onClick={submitFile}
            style={{ margin: "auto" }}
            id="traineeFile-submit-button"
          >
            Submit
          </Button>
        </div>
      )}
      {currentTab === "Manuel" && (
        <div>
          <iframe
            height="1500"
            style={{ width: "100%" }}
            frameBorder="0"
            src={config.pdfUrl}
            title="DLTS PDF"
            id="inner"
            onLoad={postMessage}
          ></iframe>
        </div>
      )}

      {isLoading && <LoadingIcon />}
      <ToastContainer />
    </div>
  );
};

export default TraineeForm;
