import "./App.css";
import Nav from "./Nav/Nav";
import TimeForm from "./Timesheet/TimeForm";
import Timesheets from "./Timesheet/Timesheets";
import Projects from "./Projects/Projects";
import ProjectDetails from "./Projects/ProjectDetails";
import Schedule from "./Schedule/Schedule";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Login from "./Login/Login";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import NewPassword from "./ForgotPassword/NewPassword";
import SuccessfullyChangedPassword from "./ForgotPassword/SuccessfullyChangedPassword";
import ValidateEmailResetCode from "./ForgotPassword/ValidateEmailResetCode";
import TraineeForm from "./pdf/TraineeForm";
import NotFound from "./NotFound/NotFound";
import {
	BrowserRouter,
	createBrowserRouter,
	Route,
	Routes,
} from "react-router-dom";
import "./index.css";
import AuthorizedLayout from "./AuthorizedLayout/AuthorizedLayout";
import SurveyFeedback from './SurveyFeedback/Feedback';
import ProjectInvitation from "./ProjectInvitation/ProjectInvitation";
import SuccessRespondedToInvitiation from "./ProjectInvitation/SuccessRespondedToInvitation";
import FailedRespondedToInvitation from "./ProjectInvitation/FailedRespondedToInvitation";


const router = createBrowserRouter([
	{
		path: "/",
		element: <Login></Login>,
	},
	{
		path: "/timesheets",
		element: <AuthorizedLayout component={<Timesheets />}></AuthorizedLayout>,
	},
	{
		path: "/timesheets/:id",
		element: <AuthorizedLayout component={<TimeForm />}></AuthorizedLayout>,
	},
	{
		path: "/projects",
		element: <AuthorizedLayout component={<Projects />}></AuthorizedLayout>,
	},
	{
		path: "/projects/:id",
		element: (
			<AuthorizedLayout component={<ProjectDetails />}></AuthorizedLayout>
		),
	},
	{
		path: "/schedule",
		element: <AuthorizedLayout component={<Schedule />}></AuthorizedLayout>,
	},
	{
		path: "/record-time",
		element: <AuthorizedLayout component={<TimeForm />}></AuthorizedLayout>,
	},
	{
		path: "/forgot-password",
		element: <ForgotPassword></ForgotPassword>,
	},
	{
		path: "/validate-code",
		element: <ValidateEmailResetCode></ValidateEmailResetCode>,
	},
	{
		path: "/new-password",
		element: <NewPassword></NewPassword>,
	},
	{
		path: "/successfully-changed-password",
		element: <SuccessfullyChangedPassword></SuccessfullyChangedPassword>,
	},
	{
		path: "trainee-form",
		element: <TraineeForm></TraineeForm>,
	},
	{
		path: 'feedback',
		element: <SurveyFeedback/>
	},
	{
		path: 'invitation',
		element: <ProjectInvitation/>
	},
	{
		path: 'invitation/success',
		element: <SuccessRespondedToInvitiation/>
	},
	{
		path: "invitation/failed",
		element: <FailedRespondedToInvitation/>
	},
	{
		path: "*",
		element: <NotFound />,
	},
]);

// Or Create your Own theme:
const theme = createTheme({
	palette: {
		primary: {
			main: "#EA710B",
		},
		secondary: {
			main: "#E33E7F",
		},
	},
});
function App() {
	return (
		<ThemeProvider theme={theme}>
			<BrowserRouter>
				<div className="App">
					<header className="App-header">
						<Nav />
					</header>
					<Routes>
						{router.routes.map((route) => (
							<Route
								key={route.path}
								path={route.path}
								element={route.element}
							/>
						))}
					</Routes>
				</div>
			</BrowserRouter>
		</ThemeProvider>
	);
}

export default App;
