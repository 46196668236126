import React from 'react'


const LocationIcon = () => {
    return(<>
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_237_2166)">
    <path d="M16.25 7.5C16.25 12.75 9.5 17.25 9.5 17.25C9.5 17.25 2.75 12.75 2.75 7.5C2.75 5.70979 3.46116 3.9929 4.72703 2.72703C5.9929 1.46116 7.70979 0.75 9.5 0.75C11.2902 0.75 13.0071 1.46116 14.273 2.72703C15.5388 3.9929 16.25 5.70979 16.25 7.5Z" stroke="#2D3C47" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9.5 9.75C10.7426 9.75 11.75 8.74264 11.75 7.5C11.75 6.25736 10.7426 5.25 9.5 5.25C8.25736 5.25 7.25 6.25736 7.25 7.5C7.25 8.74264 8.25736 9.75 9.5 9.75Z" stroke="#2D3C47" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_237_2166">
    <rect width="18" height="18" fill="white" transform="translate(0.5)"/>
    </clipPath>
    </defs>
</svg>
</>)
}

export default LocationIcon;