import React, { useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import BottomNav from "../Nav/BottomNav";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import StatusIconText from "./StatusIconText";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch } from "react-redux";
import FormControlLabel from "@mui/material/FormControlLabel";
import { startLoading, loadProjects } from "../Projects/projectSlice";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import LoadingIcon from "../Icons/LoadingIcon";
import axios from "axios";
import config from "../global/config";
import { useEffect } from "react";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const Projects = () => {
  const dispatch = useDispatch();
  var token = localStorage.getItem("instructorToken");

  useEffect(() => {
    dispatch(startLoading())
    axios.get(config.baseUrl + "/query/Projects/", {headers: {Authorization: `Bearer ${token}`}})
    .then((response) => {
        dispatch(loadProjects(response.data));
      })
      .catch((error) => {});
  }, []);

  const [currentTab, setCurrentTab] = useState("All");
  const [includeCompletedProjects, setIncludeCompletedProjects] =
    useState(false);
  const [searchPhrase, setSearchPhrase] = useState("");
  const navigate = useNavigate();

  const projects = useSelector((state) => state.projects.projects);
  const isLoading = useSelector((state) => state.projects.loading);
  if (!localStorage.getItem("instructorToken")) {
    return <Navigate to="/" />;
  }

  function formatDate(d) {
    return new Date(d).toDateString();
  }

  return (
    <div style={{ marginBottom: "175px" }}>
      <h1 className="header-text left-align">Project Information</h1>
      <Grid container>
        <Grid item xs={3}>
          <button
            className={currentTab === "All" ? "black-button" : "grey-button"}
            onClick={() => {
              setCurrentTab("All");
            }}
          >
            All
          </button>
        </Grid>
        <Grid item xs={5}>
          <button
            className={
              currentTab === "InProgress" ? "black-button" : "grey-button"
            }
            onClick={() => setCurrentTab("InProgress")}
          >
            In-Progress
          </button>
        </Grid>
        <Grid item xs={4}>
          <button
            className={
              currentTab === "Upcoming" ? "black-button" : "grey-button"
            }
            onClick={() => setCurrentTab("Upcoming")}
          >
            Upcoming
          </button>
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: "20px", marginBottom: "20px" }}>
        <Grid item xs={5}>
          <Search onKeyUp={(e) => setSearchPhrase(e.target.value)}>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
            />
          </Search>
        </Grid>
      </Grid>
      {currentTab === "All" && (
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e) => {
                setIncludeCompletedProjects(e.target.checked);
              }}
              defaultChecked={includeCompletedProjects}
            />
          }
          label="Include completed projects"
        />
      )}
      {isLoading && <LoadingIcon />}
      {!isLoading && projects.length === 0 && (
        <p className="light-grey-text">No active projects found.</p>
      )}
      {projects
        .filter(
          (p) =>
            (p.projectStatus === currentTab ||
              (currentTab === "All" &&
                (p.projectStatus !== "Completed" ||
                  includeCompletedProjects))) &&
            (!searchPhrase ||
              p.name.toLowerCase().includes(searchPhrase.toLowerCase()))
        )
        .map((project, i) => {
          return (
            <Card
              sx={{ marginTop: "10px" }}
              key={i}
              onClick={() =>
                navigate(`/projects/${project.id}`, {
                  state: { project: project },
                })
              }
            >
              <CardContent>
                <Grid container>
                  <Grid item xs={12}>
                    <h3>
                      <span className="header-text left-align">
                        {project.name}
                      </span>{" "}
                    </h3>
                  </Grid>
                  <Grid item xs={12}>
                    <h4 className="header-text left-align">
                      {formatDate(project.startDate)} -{" "}
                      {formatDate(project.endDate)}
                    </h4>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <h3>
                      <StatusIconText
                        projectStatus={project.projectStatus}
                      ></StatusIconText>
                    </h3>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions></CardActions>
            </Card>
          );
        })}

      <BottomNav activePage="overview" />
    </div>
  );
};

export default Projects;
