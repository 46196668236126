
const passwordValidator = {
    validate
}


function validate(password) {
    if(!password) return false;
    if(password.length < 6) return false;
    if(
        !password.includes('a') && !password.includes('b')
        && !password.includes('c') && !password.includes('d')
        && !password.includes('e') && !password.includes('f')
        && !password.includes('g') && !password.includes('h')
        && !password.includes('i') && !password.includes('j')
        && !password.includes('k') && !password.includes('l')
        && !password.includes('m') && !password.includes('n')
        && !password.includes('o') && !password.includes('p')
        && !password.includes('q') && !password.includes('r')
        && !password.includes('s') && !password.includes('t')
        && !password.includes('u') && !password.includes('v')
        && !password.includes('w') && !password.includes('x')
        && !password.includes('y') && !password.includes('z')
        )
        {
            return false;
        }
    if(
        !password.includes('A') && !password.includes('B')
        && !password.includes('C') && !password.includes('D')
        && !password.includes('E') && !password.includes('F')
        && !password.includes('G') && !password.includes('H')
        && !password.includes('I') && !password.includes('J')
        && !password.includes('K') && !password.includes('L')
        && !password.includes('M') && !password.includes('N')
        && !password.includes('O') && !password.includes('P')
        && !password.includes('Q') && !password.includes('R')
        && !password.includes('S') && !password.includes('T')
        && !password.includes('U') && !password.includes('V')
        && !password.includes('W') && !password.includes('X')
        && !password.includes('Y') && !password.includes('Z')
    )
    {
        return false;
    }
    if(
        !password.includes('~') && !password.includes('`') &&
        !password.includes('!') && !password.includes('@') &&
        !password.includes('#') && !password.includes('$') &&
        !password.includes('%') && !password.includes('^') &&
        !password.includes('&') && !password.includes('*') &&
        !password.includes('(') && !password.includes(')') &&
        !password.includes('_') && !password.includes('-') &&
        !password.includes('+') && !password.includes('=') &&
        !password.includes('[') && !password.includes(']') &&
        !password.includes('{') && !password.includes('}') &&
        !password.includes(';') && !password.includes(':') &&
        !password.includes('"') && !password.includes("'") &&
        !password.includes(',') && !password.includes('<') &&
        !password.includes('>') && !password.includes('.') &&
        !password.includes('/') && !password.includes('?')
    )
    {
        return false;
    }
    return true;
}

export default passwordValidator;