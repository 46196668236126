import AvailabilityRangeModal from "./AvailabilityRangeModal";
const AvailabilityModalProxy = (props) => {
  const {type, dateData, handleClose, open, handleSubmit, setDateData, setSelectedDate} = props;

  return (
      type === "range" ?
          <AvailabilityRangeModal setDateData={setDateData} dateData={dateData} handleClose={handleClose} open={open} setSelectedDate={setSelectedDate} handleSubmit={handleSubmit}/> :
          null
  )
}
export default AvailabilityModalProxy;
