import React from 'react';

const UpArrowIcon = () => {
    return (<>
                <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_204_1418)">
                <path d="M6.58053 0.98098C6.35043 0.98098 6.12036 1.07747 5.94493 1.27006L0.42451 7.3334C0.0733403 7.7191 0.0733403 8.34446 0.42451 8.73001C0.77554 9.11555 1.34479 9.11555 1.69599 8.73001L6.58053 3.36483L11.4651 8.72982C11.8163 9.11537 12.3855 9.11537 12.7365 8.72982C13.0878 8.34427 13.0878 7.71892 12.7365 7.33321L7.21612 1.26986C7.0406 1.07725 6.81054 0.98098 6.58052 0.98098H6.58053Z" fill="#EA710B"/>
                </g>
                <defs>
                <clipPath id="clip0_204_1418">
                <rect width="12.8388" height="8.03819" fill="white" transform="matrix(1 0 0 -1 0.161133 9.01917)"/>
                </clipPath>
                </defs>
                </svg>
    </>)
}


export default UpArrowIcon;