import { configureStore } from '@reduxjs/toolkit'
import projectReducer from '../Projects/projectSlice'
import unavailableDaysReducer from '../Schedule/unavailableDaysSlice'
export const store = configureStore({
    reducer: {
        projects: projectReducer,
        unavailableDays: unavailableDaysReducer
    },
})

