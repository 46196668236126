import React from 'react';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

const SuccessfullyChangedPassword = () => {
    const navigate = useNavigate();
    return (<>
    <p style={{textAlign:"center", marginTop: "200px"}}>
        <svg width="107" height="107" viewBox="0 0 107 107" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M53.3095 106.619C82.7516 106.619 106.619 82.7516 106.619 53.3095C106.619 23.8675 82.7516 0 53.3095 0C23.8675 0 0 23.8675 0 53.3095C0 82.7516 23.8675 106.619 53.3095 106.619Z" fill="#EDCD1F"/>
        <path d="M44.1682 82.0209C43.158 82.0209 42.186 81.6345 41.4513 80.9411L21.2013 61.8342C20.4377 61.1136 19.9916 60.1192 19.9611 59.0698C19.9307 58.0203 20.3184 57.0017 21.039 56.2381C21.7596 55.4745 22.7539 55.0284 23.8034 54.998C24.8529 54.9676 25.8714 55.3552 26.6351 56.0758L43.9489 72.4136L79.768 32.9418C80.4735 32.1644 81.4589 31.699 82.5075 31.6481C83.5561 31.5972 84.5819 31.9649 85.3594 32.6703C86.1369 33.3758 86.6022 34.3612 86.6531 35.4097C86.7041 36.4583 86.3363 37.4842 85.6309 38.2616L47.0988 80.7226C46.7451 81.1123 46.3173 81.4276 45.8403 81.6499C45.3634 81.8723 44.8469 81.9973 44.321 82.0177C44.2695 82.0193 44.2189 82.0209 44.1682 82.0209Z" fill="#2D3C47"/>
        </svg>
    </p>
    <h1 className="header-text">Password changed!</h1>
    <p className="grey-text">Your password has been changed successfully. Use your new password to log in.</p>
    <Button onClick={() => navigate("/")} id="success-login-button" style={{width: "100%", marginTop: "25px", marginBottom: "200px"}}>Login</Button>
    </>)
}

export default SuccessfullyChangedPassword;