import { useNavigate, useLocation } from 'react-router-dom';
import BackIconText from '../Icons/BackIconText';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import React, { useState } from 'react';
import LoadingIcon from '../Icons/LoadingIcon';
import config from '../global/config';
import axios from 'axios';
import passwordValidator from '../services/passwordValidator';

const NewPassword = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [invalidMessage, setInvalidMessage] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
    const {state} = useLocation();

    function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        if(newPassword !== newPasswordConfirm) {
            setInvalidMessage("Passwords do not match.");
            return;
        }

        if(!passwordValidator.validate(newPassword)) {
            setInvalidMessage("Password does not meet strength requirements. Password must be at least 6 characters, have one uppercase letter, one lowercase letter, one number, and one special character."); // TODO:
            return;
        }
    
        setIsLoading(true);
        axios.post(config.baseUrl + "/command/auth/changepassword", {
            Email: state.email,
            ResetCode: state.resetCode,
            NewPassword: newPassword,
            ConfirmNewPassword: newPasswordConfirm
        }, {
            headers: {
                'Authorization': 'Bearer ' + state.token
            }
        })
        .then((response) => {
            setIsLoading(false);
            navigate("/successfully-changed-password")
        })
        .catch((error) => {
            setIsLoading(false);
            setInvalidMessage("Something Went Wrong");
        })
    }

    return (<div>
        <form onSubmit={handleSubmit}>
            <h3 style={{cursor: "pointer"}} onClick={() => navigate("/")}>
                <BackIconText></BackIconText>
            </h3>
            <h2 className="header-text" style={{textAlign:"left"}}>Create new password</h2>
            <p className="grey-text" style={{textAlign:"left"}}>Your new password must be different from previously used passwords.</p>
            {isLoading &&<LoadingIcon/>}
            <TextField
                        id="password-input"
                        label="Password"
                        type="password"
                        required
                        autoComplete="current-password"
                        style={{width: "100%", marginTop:"30px"}}
                        onChange={(e) => setNewPassword(e.target.value)}
                        />
            <TextField
                        id="confirm-password-input"
                        label="Confirm password"
                        type="password"
                        required
                        style={{width: "100%", marginTop:"30px"}}
                        onChange={(e) => setNewPasswordConfirm(e.target.value)}
                        />
                        <p style={{color: "red"}}>{invalidMessage}</p>
            <Button type="submit" id="change-password-button" style={{width: "100%", marginTop: "25px"}}>RESET</Button>
        </form>
    </div>)
}

export default NewPassword;