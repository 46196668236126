import React from "react";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./Modal.css";

const ModalForm = ({
	noteContent,
	setNoteContent,
	buttonType,
	submitFile,
	closeModal,
}) => {
	const handleBackdropClick = (e) => {
		if (e.target.classList.contains("modal")) {
			closeModal();
		}
	};

	const handleSubmit = () => {
		const cleanedValue = noteContent.replace(/^\s+|\s+$/g, "");
		setNoteContent(cleanedValue);
		submitFile();
	};

	const handleKeyDown = (e) => {
		if (e.key === "Enter" && !e.shiftKey) {
			e.preventDefault();
			handleSubmit();
		}
	};

	const handleTextareaChange = (e) => {
		const inputValue = e.target.value;
		const trimmedValue = inputValue.trim();
		if (trimmedValue === "") {
			setNoteContent("");
		} else {
			setNoteContent(inputValue);
		}
	};

	return (
		<div className="modal" onClick={handleBackdropClick}>
			<div className="modal-content" style={{ position: "relative" }}>
				<Button
					onClick={closeModal}
					style={{
						position: "absolute",
						right: "1rem",
						top: "1rem",
						minWidth: "auto",
					}}
					id="close-modal-button">
					<CloseIcon />
				</Button>
				<h2 style={{ color: "#2d3c47", fontFamily: "Helvetica" }}>
					Project Notes
				</h2>
				<textarea
					value={noteContent}
					onChange={handleTextareaChange}
					style={{
						resize: "none",
						display: "block",
						marginTop: "4px",
						marginBottom: "30px",
						fontFamily: "Helvetica",
						maxWidth: "-webkit-fill-available",
					}}
					onKeyDown={handleKeyDown}
					rows={8}
					cols={50}
					id="note"
					name="note"
				/>
				{noteContent.length > 25000 && (
					<p className="error_box">Max limit is 2500 characters</p>
				)}
				{buttonType.length > 0 && (
					<Button
						type="submit"
						onClick={handleSubmit}
						style={{ margin: "auto" }}
						id="traineeFile-submit-button">
						{buttonType === "update" ? "Save" : "Add notes"}
					</Button>
				)}
			</div>
		</div>
	);
};

export default ModalForm;
