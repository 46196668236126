import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import config from '../global/config'
import { Navigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux'


const DeleteTraineeFormModal = (props) => {
    const projects = useSelector((state) => state.projects.projects);
    var token = localStorage.getItem('instructorToken');

    if (!token) {
        return <Navigate to="/" />
    }

    function submitDeleteTraineeForm() {
      const url = config.baseUrl + "/command/instructor/deleteForm";
      axios.post(url, {LinkId: props.form.id}, {
          headers: {
              'Authorization': 'Bearer ' + token
      }
      })
      .then((response) => {
        toast.success('Successfully Deleted Form.', {
            position: toast.POSITION.BOTTOM_CENTER
        })
      })
      .catch((error) => {
          toast.error('Please reauthenticate.', {
              position: toast.POSITION.BOTTOM_CENTER
          })
          localStorage.removeItem('instructorToken');
          return <Navigate to="/" />
      })
      props.handleClose();
    }

    return (<>
          <Dialog
          open={props.open}
          onClose={props.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        ><br></br>
          <DialogTitle id="alert-dialog-title">
            Delete Trainee Form
          </DialogTitle>
          <DialogContent>
          <IconButton
            aria-label="close"
            onClick={props.handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
            <DialogContentText id="alert-dialog-description">
                    Are you sure you would like to delete <a href={props.formUrl}>{props.formName}</a>?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={submitDeleteTraineeForm} style={{margin: "auto" }} id="delete-traineeform-submit-button">Delete</Button>
          </DialogActions>
        </Dialog>
        <ToastContainer/>
    </>)
}

export default DeleteTraineeFormModal;
